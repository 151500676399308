<template>
  <div
    class="test-plans-detail font-inter pl-2 pr-2 pb-2"
    fluid
  >
    <v-card
      class="test-plans-section-header white pt-4 px-6 pb-4 mt-2 mb-2"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <div class="d-flex flex-row align-center justify-space-between">
        <div class="d-flex flex-row align-center">
          <button
            class="btn-nav-back d-flex"
            @click="handleBackClick"
          >
            <v-icon
              class="pl-2"
              size="large"
              color="#0C111D"
            >
              mdi-arrow-left
            </v-icon>
          </button>
          <h2
            class="ml-2"
            style="min-width: 267px;"
          >
            Functionality Test Plan
          </h2>
          <div class="progress-container rounded-pill ml-6">
            <v-progress-linear
              v-for="(x, index) of progress(value).slice().reverse()"
              :key="index"
              :color="x.color"
              rounded
              background-color="transparent"
              :height="8"
              :value="x.value"
            />
          </div>
          <div class="ml-3">
            20%
          </div>
        </div>
        <div class="d-flex flex-row justify-end">
          <v-btn
            class="text-capitalize mr-3"
            depressed
            @click="handleRerunClick"
          >
            {{ $t('rerun') }}
          </v-btn>
          <v-btn
            class="text-capitalize primary"
            depressed
          >
            {{ $t('Complete') }}
          </v-btn>
        </div>
      </div>
    </v-card>
    <div class="test-plans-detail-wrapper">
      <placeholder v-if="testPlans.length <= 0" />
      <v-card
        v-else
        class="py-6 px-6"
        rounded="lg"
        elevation="0"
        width="100%"
      >
        <div class="d-flex">
          <search-field
            v-model="searchFilter"
            class="search-input mr-2"
            :placeholder="$t('searchByName')"
          />
          <!-- <plans-list-filter @filters="applyFilters" /> -->
          <v-menu
            left
            :close-on-content-click="false"
            :nudge-bottom="4"
            offset-y
            min-width="191"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                class="ml-auto pa-0 rounded-lg"
                height="40"
                min-width="40"
                elevation="0"
                v-on="on"
              >
                <v-icon size="16px">
                  mdi-cog-outline
                </v-icon>
              </v-btn>
            </template>
            <v-list
              width="191"
              class="d-flex flex-column justify-space-between rounded-xl"
            >
              <v-list-item class="font-inter custom-text-14 custom-menu-item my-2">
                {{ $t('columns') }}
              </v-list-item>
              <v-list-item
                v-for="(header, index) in filteredMenuHeaders"
                :key="index"
                class="custom-menu-item"
              >
                <v-checkbox
                  v-model="header.isSelected"
                  :value="header.isSelected"
                  :true-value="true"
                  :false-value="false"
                  off-icon="mdi-checkbox-blank"
                  class="ma-0 pa-0"
                  color="blue"
                  :size="16"
                  hide-details
                >
                  <template v-slot:label>
                    <div class="custom-color-0c111d font-inter custom-text-14">
                      {{ header.text }}
                    </div>
                  </template>
                </v-checkbox>
              </v-list-item>
              <v-list-item
                class="custom-menu-item my-2"
                @click="handleColumnReset()"
              >
                <div
                  class="custom-text-12 font-weight-semibold font-inter custom-color-d0d5dd"
                  :class="{ 'blue--text font-weight-semibold ': isColumnFilter }"
                >
                  {{ $t('Restore default') }}
                </div>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <plans-list
          :data="testPlans"
          :tab="tab"
          :headers="listHeaders"
        />
        <select-test-run-status-dialog
          v-model="showRerunDialog"
          @close="handleCloseRerunDialog"
          @rerun="handleRerunPlan"
        />
      </v-card>
      <div class="d-flex flex-row justify-end">
        <v-btn
          class="py-2 px-6 mb-4 text-capitalize mr-3 action-button"
          color="#F2F4F7"
          depressed
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          class="py-2 px-6 mb-4 text-capitalize mr-6 primary action-button"
          depressed
        >
          {{ $t('save') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Placeholder from '@/views/Tests/Plans/Components/Placeholder';
import SearchField from '@/components/Form/SearchField';
import PlansList from '@/views/Tests/Plans/Components/List/PlansList';
import SelectTestRunStatusDialog from '@/views/Tests/Plans/Components/SelectTestRunStatusDialog';

import { testPlans } from '@/constants/data.js';
import { TestPlansTableHeader } from '@/constants/grid.js';
import { testPlansProgressColor } from '@/constants/data.js';

export default {
  components: {
    SelectTestRunStatusDialog,
    Placeholder,
    SearchField,
    PlansList,
  },
  data() {
    return {
      showRerunDialog: false,
      testPlans,
      menuOpen: false,
      headers: TestPlansTableHeader,
      value: {
        id: 1,
        name: 'Smoke Test Run',
        priority: 'High',
        status: 'New',
        configurations: [{ title: 'Browsers' }],
        testruns: 8,
        creationdate: '06/17/2023',
        tag: ['#projectateam'],
        progress: [20, 40, 60, 80],
      },
    };
  },
  computed: {
    ...mapGetters({
      currentOrg: 'user/currentAccount',
    }),
    listHeaders() {
      const filtered = this.headers.filter((x) => x.isSelected);
      if (filtered.length < this.headers.length)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = true
      else
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = false
      return filtered;
    },
    filteredMenuHeaders() {
      const filtered = this.headers.filter((header) => header.value != 'actions');
      return filtered;
    },
  },
  methods: {
    handleTab(value) {
      this.tab = value;
    },
    handleColumnReset() {
      this.headers = this.headers.map((header) => {
        header.isSelected = true;
        return header;
      })
    },
    handleRerunClick() {
      this.showRerunDialog = true;
    },
    handleCloseRerunDialog() {
      this.showRerunDialog = false;
    },
    handleEditClick() {
      this.isEditPlan = true;
    },
    handleBackClick() {
      this.$router.replace({ name: 'TestPlans' });
    },
    progress(item) {
      const result = item.progress.map((x) => {
        const color =
          x < 25
            ? testPlansProgressColor[0]
            : x < 50
            ? testPlansProgressColor[1]
            : x < 75
            ? testPlansProgressColor[4]
            : testPlansProgressColor[3];
        return {
          value: x,
          color,
        };
      });
      return result;
    },
  },
};
</script>

<style lang="scss">
.test-plans-detail {
  height: 100%;
  max-height: calc(100vh - 90px);
  background: #f2f4f7;

  display: flex;
  flex-direction: column;
}

.test-plans-detail-wrapper {
  height: 100%;
  max-height: calc(100% - 116px - 8px);
  background: #ffffff;
  border-radius: 8px;
}

.progress-container {
  position: relative;
  width: 158px;
  height: 8px;
  background: #eaecf0;
  overflow: hidden;
}

.edit-inputs{
  max-width: 476px;
}

.v-input {
  .v-input__control {
    .v-input__slot {
      padding-left: 0 !important;
      textarea{
        line-height: 14px;
      }
    }
  }
}

.none{
  display: none;
}

.btn-show{
  display: block !important;
}

.action-button{
  width: 140px;
}
.v-progress-linear__determinate {
  border-radius: 4px;
}
</style>
<style lang="scss" scoped>
.progress-container {
  position: relative;
  height: 8px;
  background: #f2f4f7;
  overflow: hidden;
}

.v-progress-linear {
  position: absolute;
  left: 0;
}
</style>
